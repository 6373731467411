import { Injectable } from '@angular/core';
import { FilterService, MultiValueFilter, SearchFilter, DateFilter } from '@ceres/filter';
import { partition } from 'lodash-es';
import { IUser, ProjectProfileFilterValues, RoleType } from '@ceres/domain';
import { ImpersonatedHttpClient, AppUserService, User } from '@ceres/shared/services';
import { ListItem } from '@ceres/frontend-helper';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { ProjectProfile, BusinessArea } from '../../shared/models';
import { environment } from '../../../environments/environment';
import { PersistentFilter } from '../../myceres/components/settingsv2/custom-filter/custom-filter';
import projectListColumns from '../../../configs/project-list-columns/project-list-columns.config';
import { EmployeeSettingsService } from '../../shared/services/employee-settings.service';

const searchFields = [
    'mpNumber::text',
    'mpTitle',
    'businessPartnerPre',
    'businessPartnerSur',
    'projectLead.name',
    'projektGruppierung',
    'projectStatus',
    'leistungsempfaenger.title',
    'orgID',
    'costCenterInternal',
    'bestellnummer',
    'angebotsnummer'
];

const isMerchant = ({ role }: User) => role && role.name === RoleType.Merchant;

@Injectable()
export class ProjectFilterService extends FilterService<ProjectProfile> {
    public columnFilters = [
        new MultiValueFilter('businessPartner.fullName'),
        new MultiValueFilter('abteilungKunde'),
        new MultiValueFilter('verrechnungszyklus'),
        new MultiValueFilter('leistungsempfaenger.title'),
        new MultiValueFilter('mpNumber'),
        new MultiValueFilter('projectLead.name'),
        new MultiValueFilter('projectStatus', [
            'projects.project-property-options.status.in-progress',
            'projects.project-property-options.status.other',
            'projects.project-property-options.status.marked-for-deletion'
        ]),
        new MultiValueFilter('projektGruppierung'),
        new MultiValueFilter('verrechnungsart'),
        new MultiValueFilter('kundenart'),
        new MultiValueFilter('updatedByEmail'),
        new MultiValueFilter('program.title'),
        new MultiValueFilter('projectTags.translationKey'),
        new MultiValueFilter('maturityLevel.translationKey'),
        new MultiValueFilter('costCenterInternal'),
        new MultiValueFilter('customerPspElement.title'),
        new MultiValueFilter('projectType.translationKey'),
        new MultiValueFilter('verrechnungsland')
    ];
    protected searchFilters = searchFields.map((key) => new SearchFilter(key));
    public globalFilters = [new DateFilter('startDate'), new DateFilter('endDate')];

    public initialColumnSetup: ListItem[] = [...projectListColumns];
    public availableColumns: ListItem[] = _.cloneDeep(this.initialColumnSetup);

    constructor(
        private readonly appUserService: AppUserService,
        private readonly http: ImpersonatedHttpClient,
        private employeeSettingsService: EmployeeSettingsService
    ) {
        super();
        this.appUserService.loggedInUser$.subscribe((user) => {
            if (isMerchant(user)) {
                this.applyBusinessAreaFilter(user.details.businessArea);
            }
        });

        this.employeeSettingsService.persistentFilter$.subscribe((data) => {
            if (!data) {
                return;
            }
            this.applyPersistentFilters(data, appUserService.loggedInUser$.getValue());
        });
        this.applyFilter('projectStatus');
    }

    applyFiscalYear({ date, dateEnd }: { date: Date; dateEnd: Date }) {
        this.globalFilters
            .filter(({ key }) => key === 'startDate' || key === 'endDate')
            .forEach((filter) => {
                filter.start = date;
                filter.end = dateEnd;
                filter.isApplied = filter.isActive();
            });
        this.triggerFilter();
    }

    private applyPersistentFilters(filtersFromSettings: PersistentFilter, user: IUser) {
        const transformedFilters = this.transformFiltersFromSettings(filtersFromSettings, user);
        if (!transformedFilters) {
            return;
        }

        const [persistentFilters, nonPersistentFilters] = partition(
            this.columnFilters,
            ({ key }) => transformedFilters[key] && transformedFilters[key].length
        );

        // push selected values
        persistentFilters.forEach((filter) => {
            filter.selected.push(...transformedFilters[filter.key].filter((val) => !filter.selected.includes(val)));
            filter.isApplied = filter.isActive();
        });
        this.columnFilters = [...persistentFilters, ...nonPersistentFilters];
        this.triggerFilter();
    }

    private applyBusinessAreaFilter(businessArea: BusinessArea) {
        if (businessArea && businessArea.title) {
            const groupingFilter = this.columnFilters.find(({ key }) => key === 'projektGruppierung');
            if (groupingFilter) {
                groupingFilter.selected.push(businessArea.title);
                this.applyFilter(groupingFilter);
            }
        }
    }

    protected fetchFilterValues(field: string, filters: string) {
        if (field === 'isFavorite') {
            return of({ isFavorite: [true, false] });
        }

        return this.http.get<ProjectProfileFilterValues>(`${environment.edgeService}/projects/profiles/filterValues`, {
            params: {
                field,
                filters
            }
        });
    }

    private transformFiltersFromSettings(filtersFromSettings: PersistentFilter, user: IUser) {
        if ('projectGrouping' in filtersFromSettings && 'onlyUserAsProjectLead' in filtersFromSettings) {
            return {
                projektGruppierung: filtersFromSettings.projectGrouping,
                'projectLead.name': filtersFromSettings.onlyUserAsProjectLead ? [user.details.name] : ''
            };
        } else if ('projectGrouping' in filtersFromSettings) {
            return {
                projektGruppierung: (filtersFromSettings as { projectGrouping }).projectGrouping
            };
        } else if ('onlyUserAsProjectLead' in filtersFromSettings) {
            return {
                'projectLead.name': (filtersFromSettings as { onlyUserAsProjectLead }).onlyUserAsProjectLead
                    ? [user.details.name]
                    : ''
            };
        } else {
            return false;
        }
    }

    public setColumnFilters(columnFilters: MultiValueFilter[]) {
        this.columnFilters = columnFilters;
    }
}
