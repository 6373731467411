import { Inject, Injectable } from '@angular/core';
import { CommonPagination, CommonLoading, ENVIRONMENT } from '@ceres/frontend-helper';
import { BehaviorSubject, Observable, of, from, forkJoin } from 'rxjs';
import { BusinessPartner, BusinessPartnerMetadata, PaginationResponse, TranslationOption } from '@ceres/domain';
import { Filter } from '@ceres/filter';
import { HttpParams } from '@angular/common/http';
import { catchError, switchMap, map } from 'rxjs/operators';
import { XlsxExportService } from '@ceres/shared/services';
import { TranslocoService } from '@ngneat/transloco';
import { ImpersonatedHttpClient } from '@ceres/shared/services';

@Injectable({
    providedIn: 'root'
})
export class BusinessPartnerService extends CommonPagination({ index: 0, size: 15 }, CommonLoading(Object)) {
    private _businessPartners: BehaviorSubject<BusinessPartner[]> = new BehaviorSubject<BusinessPartner[]>([]);
    public businessPartners$: Observable<BusinessPartner[]> = this._businessPartners.asObservable();

    private _appliedFilters: Filter[] = [];

    constructor(
        private http: ImpersonatedHttpClient,
        private xlsxExportService: XlsxExportService,
        private translateService: TranslocoService,
        @Inject(ENVIRONMENT) private readonly environment: { edgeService: string }
    ) {
        super();
    }

    public set appliedFilters(appliedFilters: Filter[]) {
        this._appliedFilters = appliedFilters;
        this.pageIndex = 0;
        this.getAll();
    }

    public get appliedFilters(): Filter[] {
        return this._appliedFilters;
    }

    public override getAll() {
        this.loading = true;
        let params = this.getPaginationParams();
        params = this.appendFilters(params);
        this.http
            .get<PaginationResponse<BusinessPartner>>(`${this.environment.edgeService}/business/partners`, {
                params
            })
            .pipe(catchError(() => of({ data: [], total: 0 })))
            .subscribe(({ data, total }) => {
                this._total.next(total);
                this._businessPartners.next(data);
                this.loading = false;
            });
    }

    public exportBusinessPartners(displayedColumns: string[]): Observable<any> {
        const requests = this.generateExportRequests();
        const columnsToAdd = [
            {
                key: 'mainCustomerContact.department',
                index: displayedColumns.indexOf('mainCustomerContact.name') + 1
            }
        ];

        return forkJoin(requests).pipe(
            map((results) => results.flat()),
            switchMap(
                (data: BusinessPartner[]): Observable<boolean | void> =>
                    data.length === 0
                        ? of(true)
                        : from(
                              this.xlsxExportService.genericExport(
                                  this.translateService.translate(
                                      'control-center.excel-export.file-title.business-partner'
                                  ),
                                  data,
                                  displayedColumns,
                                  '',
                                  columnsToAdd
                              )
                          )
            )
        );
    }

    public getMetadata(): Promise<BusinessPartnerMetadata> {
        return this.http
            .get<BusinessPartnerMetadata>(`${this.environment.edgeService}/business/partners/metadata`)
            .toPromise();
    }

    public getGpTypeOptions(): Promise<TranslationOption[]> {
        return this.http
            .get<TranslationOption[]>(`${this.environment.edgeService}/business/partners/gp-type-options`)
            .toPromise();
    }

    private appendFilters(params: HttpParams) {
        if (this.appliedFilters.length > 0) {
            params = params.append('filters', JSON.stringify(this.appliedFilters));
        }
        return params;
    }

    private generateExportRequests(): Observable<BusinessPartner[]>[] {
        const pageSize = 2000;
        const totalBusinessPartners = this._total.getValue();
        const totalPages = Math.ceil(totalBusinessPartners / pageSize);
        const requests: Observable<BusinessPartner[]>[] = [];

        for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
            const params = this.getPaginationParamsForExport(pageIndex, pageSize);

            const request = this.http
                .get<
                    PaginationResponse<BusinessPartner>
                >(`${this.environment.edgeService}/business/partners`, { params })
                .pipe(
                    catchError(() => of({ data: [] as BusinessPartner[], total: 0 })),
                    map((response) => response.data)
                );

            requests.push(request);
        }

        return requests;
    }

    private getPaginationParamsForExport(pageIndex: number, pageSize: number) {
        let params = new HttpParams();
        params = params.append('page', `${pageIndex}`);
        params = params.append('size', `${pageSize}`);
        if (this.pageSort && this.pageSort.key && this.pageSort.direction) {
            params = params.append('sort', `${this.pageSort.key},${this.pageSort.direction.toUpperCase()}`);
        }
        params = this.appendFilters(params);
        return params;
    }
}
